var popup = (function($){
	//購入確認画面のポップアップ
	return {
		view : $('<div />', {class: 'popup'})
			.append($('<div />', {class: 'box'})
				.append($('<section />', {class: 'titleDeco popupBox'})
				.append($('<p />', {class: 'popupText'}))
				.append($('<p />', {class: 'reload btn red Deco width', onclick: 'window.location.reload()'}).text('OK'))
				.append($('<p />', {class: 'action btn red Deco width',
					onclick: 'window.location = \'/login?uri=\' + location.pathname + location.search + \'&back=\' + location.pathname'}))
			)
		),
		open : function() {
			$('body').append(this.view);
		},

		close : function() {
			this.view.remove();
		},

		//通信エラーの場合
		error : function() {
			this.contentChange(
				'通信エラーが発生しました。<br />後ほどお試しください',
				'',
				'OK'
			);
			this.open();
		},

		//占いの失敗
		fortuneFial : function() {
			this.contentChange(
				'鑑定に失敗しました<br>お手数ですが再度お試し下さい',
				'',
				''
			);
			this.open();
		},

		//Amebaの認証の場合
		attestation : function() {
			this.contentChange(
				'Amebaの認証が切れています',
				'ログインする',
				'あとで'
			);
			this.open();
		},

		// ポップアップの内容を引数に合わせて変更する
		contentChange: function(text, positiveButtonText, negativeButtonText) {
			var $content = this.view,
				$popupText = $content.find('.popupText'),
				$action = $content.find('.action'),
				$after = $content.find('.after'),

				isPositiveButton = typeof positiveButtonText === 'string' && positiveButtonText !== '',
				isNegativeButton = typeof negativeButtonText === 'string' && negativeButtonText !== '',
				isChangeText = typeof text === 'string' && text !== '';

			if(isChangeText) {
				$popupText.html(text);
			}

			if(isPositiveButton) {
				$action.html(positiveButtonText);
			}

			if(isNegativeButton) {
				$after.html(negativeButtonText);
			}

			$action[(isPositiveButton ? 'remove' : 'add') + 'Class']('off');
			$after[(isNegativeButton ? 'remove' : 'add') + 'Class']('off');
		}
	};
})(jQuery);
